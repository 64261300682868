<template>
    <layout>
        <were-changes-alert
            :were-changes="wereChanges"
            @save="onSaveColorButtonClick"
        />
        <page-header :title="pageTitle" :items="breadcrumbsItems" />

        <div class="mb-3">
            <button
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
                @click="onSaveColorButtonClick"
            >
                <i class="fas fa-save mr-1" />
                Save
            </button>
        </div>

        <div class="row card-box">
            <div class="col-6 form-group mb-3">
                <label>Color Name<span class="text-danger">*</span></label>
                <input
                    v-model="color.name"
                    type="text"
                    class="form-control"
                    placeholder="Color name"
                    required
                />
            </div>

            <div class="col-6 form-group mb-3">
                <label>Color Hex</label>
                <color-picker
                    :key="color.id"
                    v-model="color.hex"
                    :color="color.hex"
                />
            </div>

            <media-chooser
                :key="color.id"
                v-model="color.images"
                multiple-mode
                recommended-size="830x300"
                label="Images"
                class="col-xl-6"
            />

            <media-chooser
                :key="`${color.id}_no_removable`"
                v-model="color.no_removable_inserts_images"
                multiple-mode
                recommended-size="830x300"
                label="No Removable Inserts Images"
                class="col-xl-6"
            />

            <media-chooser
                :key="`${color.id}_cart`"
                v-model="color.cart_image"
                recommended-size="830x300"
                label="Cart Image"
                class="col-xl-6"
            />

            <media-chooser
                :key="`${color.id}_cart_no_removable`"
                v-model="color.cart_no_removable_inserts_image"
                recommended-size="830x300"
                label="Cart No Removable Inserts Image"
                class="col-xl-6"
            />

            <product-video
                :key="`${color.id}_v`"
                v-model="color.video"
                class="col-12"
            />
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';
import ProductVideo from '@components/products/ProductVideo';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    page: {
        title: 'Product Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        ColorPicker,
        MediaChooser,
        ProductVideo,
        WereChangesAlert
    },

    props: {
        productId: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            pageTitle: 'Product Color Details',
            breadcrumbsItems: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Product List',
                    to: '/products'
                }
            ],
            color: {
                images: [],
                video: {
                    thumbnail: {}
                },
                no_removable_inserts_images: []
            },
            initValueStringify: '',
            editMode: false
        };
    },

    computed: {
        wereChanges() {
            if (!this.color || !this.editMode) {
                return false;
            }

            return JSON.stringify(this.color) !== this.initValueStringify;
        }
    },

    async mounted() {
        this.breadcrumbsItems = [
            ...this.breadcrumbsItems,
            {
                text: 'Product Details',
                to: `/products/${this.productId}/edit`
            }
        ];

        if (!this.id) {
            this.breadcrumbsItems = [
                ...this.breadcrumbsItems,
                {
                    text: 'Add color'
                }
            ];

            return;
        }

        this.breadcrumbsItems = [
            ...this.breadcrumbsItems,
            {
                text: 'Color Details'
            }
        ];

        this.editMode = true;
        this.color = await this.findProductColor(this.id);

        this.initValueStringify = JSON.stringify(this.color);
    },

    methods: {
        ...mapActions({
            storeProductColor: 'products/storeProductColor',
            findProductColor: 'products/findProductColor',
            updateProductColor: 'products/updateProductColor'
        }),

        async onSaveColorButtonClick() {
            try {
                if (!this.editMode) {
                    const color = await this.storeProductColor({
                        productId: this.productId,
                        color: this.color
                    });

                    this.$toaster('Color has been added!');

                    this.$router.push(
                        `/products/${this.productId}/colors/${color.id}/edit`
                    );

                    return;
                }

                this.color = await this.updateProductColor({
                    id: this.id,
                    color: this.color
                });

                this.initValueStringify = JSON.stringify(this.color);

                this.$toaster('Product has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError('Product update error!');
            }
        }
    }
};
</script>
